import StorageKeys from '../constants/storageKeys';

const calendarFiltersStorageKey = (user_id) => {
  return `${StorageKeys.CALENDAR_FILTERS}_${user_id}`
}

export const getCalendarFilters = (user_id) => {
  const key = calendarFiltersStorageKey(user_id)
  return JSON.parse(localStorage.getItem(key)) || {}
}

export const setCalendarFilters = (user_id, filters) => {
  const key = calendarFiltersStorageKey(user_id)
  localStorage.setItem(key, JSON.stringify(filters));
}
