import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep, isUndefined, isEmpty, isNull } from "lodash";
import moment from "moment-timezone";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import { 
  renderEmailField,
  renderInputField,
  renderPhoneField,
  renderSearchSelectField,
  renderCheckboxField
} from '../../../components/formFields'
import { Row, Col, notification, Form, Checkbox, Button, Modal, Upload, Tooltip } from 'antd';
import { createAdminUser, updateAdminUser, getUser, sendClientInvitation, getAdminUserPermissions, uploadUserPhoto, refreshToken, getPlanLimit } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import Roles from '../../../constants/roles';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';
import { getTimezoneOptions } from '../../../helpers/timezoneHelper';
import { getDeviceId } from '../../../helpers/deviceHelper';
import { formatDateTimeLong } from '../../../helpers/dateHelper';
import { resizeFile, isValidFileType, getBase64 } from '../../../helpers/imageHelper';
import { MdOutlineClose, MdArrowForwardIos, MdPhotoCamera, MdInfoOutline } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import AppContext from '../../../app/context';
import djPlaceholderImage from '../../../images/dj-placeholder.png';
import { getCountryCodeForPhoneNumber, getDefaultPhoneCountryCode, getPhoneNumber, getFormattedPhoneNumber } from '../../../helpers/addressHelper';

const NewEmployeePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [phoneId, setPhoneId] = useState("");
  const [isConfirmInvitationModalVisible, setConfirmInvitationModalVisible] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isUpdateEmailErrorModalVisible, setUpdateEmailErrorModalVisible] = useState(false);
  const [isUpdateEmailConfirmationModalVisible, setUpdateEmailConfirmationModalVisible] = useState(false);
  const [requestBody, setRequestBody] = useState(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState("US");
  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);

  useDocumentTitle("Staff Details")
  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const [hasBetaFeature] = useBetaFeatures()

  const userId = params.id;
  const isNew = isUndefined(userId);
  const isReadOnly = user.role == Roles.OWNER && auth.user.user_id != user.user_id
  const isCurrentUser = auth.user.user_id == user.user_id

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isNew) {
      refreshPage();
    } else {
      refreshNewPage();
    }
    loadBetaFeatures()
  }, []);

  const refreshPage = async () => {
    try {
      const userResults = await sendRequest(getUser(userId))
      if (isEmpty(userResults) || userResults.user_type_id == 2) {
        navigate("/admin/staff")
        return
      }
      var phone = ""
      if (userResults.phone_numbers && userResults.phone_numbers.length > 0) {
        phone = userResults.phone_numbers[0].phone_number
        setPhoneId(userResults.phone_numbers[0].phone_id)
      }
      const formValues = {
        first_name: userResults.first_name,
        last_name: userResults.last_name,
        alias: userResults.alias,
        email: userResults.email,
        phone: getFormattedPhoneNumber(phone),
        timezone: userResults.timezone ?? moment.tz.guess()
      }
      setUser(userResults)
      setSelectedPermissions(userResults.permissions)
      form.setFieldsValue(formValues)

      if (isEmpty(phone)) {
        setPhoneCountryCode(getDefaultPhoneCountryCode())
      } else {
        setPhoneCountryCode(getCountryCodeForPhoneNumber(phone))
      }

      await populatePermissions()
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const refreshNewPage = async () => {
    try {
      const planLimitResults = await sendRequest(getPlanLimit())
      if (!planLimitResults.create_allowed) {
        navigate("/admin/staff")
        return
      }

      form.setFieldsValue({ timezone: moment.tz.guess(), send_invitation: true })

      await populatePermissions()

      setPhoneCountryCode(getDefaultPhoneCountryCode())
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const loadBetaFeatures = async () => {
    try {
      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      setNewInvoicesEnabled(FeatureFlags.INVOICES || betaResults)
    } catch {}
  }

  const populatePermissions = async () => {
    try {
      const permissionResults = await sendRequest(getAdminUserPermissions());
      setPermissions(permissionResults)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      if (isNew) {
        var body = {
          ...values,
          phone: getPhoneNumber(values["phone"], phoneCountryCode),
          permissions: selectedPermissions
        }
        setRequestBody(body)
        setSaving(true)
        // Create staff
        const adminUserResults = await sendRequest(createAdminUser(body))
        if (adminUserResults.status == "user-exists") {
          setUpdateEmailErrorModalVisible(true)
          setSaving(false)
          return
        }
        // Upload photo if needed
        if (adminUserResults.user && adminUserResults.user.user_id && imageFile) {
          // Resize photo
          const newFile = await resizeFile(imageFile)

          // Get blob from URI
          let response = await fetch(newFile);
          let blob = await response.blob()

          // Upload photo
          const formData = new FormData();
          formData.append('photo', blob);
          formData.append('user_id', adminUserResults.user.user_id);
          await sendRequest(uploadUserPhoto(formData))
        }
        displaySuccess()
      } else {
        var body = {
          ...values,
          phone: getPhoneNumber(values["phone"], phoneCountryCode),
          phone_id: phoneId,
          permissions: selectedPermissions
        }
        setRequestBody(body)
        if (user.email != values.email) {
          setUpdateEmailConfirmationModalVisible(true)
        } else {
          confirmUpdate(body)
        }
      }
    } catch {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const confirmEmailUpdate = () => {
    setUpdateEmailConfirmationModalVisible(false)
    confirmUpdate(requestBody)
  }

  const confirmUpdate = async (body) => {
    try {
      setSaving(true)
      const updateResponse = await sendRequest(updateAdminUser(user.user_id, body))
      if (updateResponse.status == "email-in-use") {
        setUpdateEmailErrorModalVisible(true)
        setSaving(false)
        return
      }
      // Upload photo (if needed)
      if (imageFile) {
        // Resize photo
        const newFile = await resizeFile(imageFile)

        // Get blob from URI
        let response = await fetch(newFile);
        let blob = await response.blob()
        
        const formData = new FormData();
        formData.append('photo', blob);
        formData.append('user_id', user.user_id);
        await sendRequest(uploadUserPhoto(formData))
      }
      // Refresh token if current user
      if (user.user_id == auth.user.user_id) {
        const authResponse = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
        setAuth(authResponse)
      }
      displaySuccess()
    } catch {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your changes have been saved.',
      duration: 3
    });
    navigate("/admin/staff");
  }

  const onSendInvitation = () => {
    setConfirmInvitationModalVisible(true)
  }

  const sendInvitation = () => {
    setConfirmInvitationModalVisible(false)
    sendRequest(sendClientInvitation({ email: user.email, type: "employee" })).then((response) => {
      refreshPage()
    })
  }

  const onPermissionsClicked = (checked, admin_user_permission_id) => {

    var newSelectedPermissions = cloneDeep(selectedPermissions)
    if (newSelectedPermissions.includes(admin_user_permission_id)) {
      newSelectedPermissions = newSelectedPermissions.filter(x => x != admin_user_permission_id)
    } else {
      newSelectedPermissions.push(admin_user_permission_id)
    }
    setSelectedPermissions(newSelectedPermissions)
  }

  const handleImageChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }
      getBase64(info.file, url => setImageUrl(url))
      setImageFile(info.file)
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const renderConfirmInvitationModal = () => {
    return (
      <Modal visible={isConfirmInvitationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmInvitationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Send Invitation</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to send an invitation to <span className="fw-700">{user.email}</span>?</div>
          <button className="primary-button" type="button" onClick={() => sendInvitation()}>Send</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmInvitationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/staff")}>
            Staff
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Detail
          </span>
        </div>
        
        <div className="fw-700 fs-24 mt-5">{isNew ? "New Staff Member" : user.first_name + " " + user.last_name}</div>
      </div>
    )
  }

  const renderActivity = () => {
    if (isNew || user.role == Roles.OWNER) {
      return null
    }
    var lastLogin = "N/A"
    var lastInvitationSent = null
    var invitationStatus = "Pending"
    var appDownload = user.login_with_app ? "Yes" : "No"
    var showInvitationButton = true
    var invitationButtonText = "Send Invitation"

    if (user.last_login_datetime) {
      lastLogin = formatDateTimeLong(user.last_login_datetime, accountSettings)
      invitationStatus = "Accepted"
      showInvitationButton = false
    }
    if (user.last_invitation_datetime) {
      invitationButtonText = "Re-send Invitation"
      lastInvitationSent = formatDateTimeLong(user.last_invitation_datetime, accountSettings)
    }
    return (
      <>
        <div className="shadow-card p-20">
          <div className="fs-20 fw-700 mb-20 line-1">Activity</div>
          <div className="bg-gray radius-8 p-10">
            <div className="fs-12 fw-700">Invitation Status: <span className="fw-500">{invitationStatus}</span></div>
            { lastInvitationSent && (
              <div className="fs-12 fw-700">Invitation Sent: <span className="fw-500">{lastInvitationSent}</span></div>
            )}
            <div className="fs-12 fw-700">Downloaded the app?: <span className="fw-500">{appDownload}</span></div>
            <div className="fs-12 fw-700">Last Login: <span className="fw-500">{lastLogin}</span></div>
          </div>
          { showInvitationButton && (
            <Button className="admin-small-button mt-15" onClick={() => onSendInvitation()}>
              {invitationButtonText}
            </Button>
          )}
        </div>
      </>
    )
  }

  const renderPermissionCheckbox = (id, tooltip = null) => {
    const foundPermission = permissions.find(x => x.admin_user_permission_id == id)
    var permissionName = foundPermission ? foundPermission.permission_name : ""
    return (
      <div className="flex-row flex-middle pv-5">
        <div className="display-flex">
          <Checkbox checked={selectedPermissions.includes(id)} onChange={(e) => onPermissionsClicked(e.target.checked, id)} disabled={isCurrentUser}>{permissionName}</Checkbox>
        </div>
        { !isNull(tooltip) && (
          <div className="display-flex">
            <Tooltip title={tooltip}>
              <MdInfoOutline style={{ fontSize: 14, marginLeft: 0, marginBottom: 0, color: '#999'}}/>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }

  const renderPermissions = () => {
    if (user.role == Roles.OWNER) {
      return (
        <div className="bg-gray radius-8 p-15">
          <div className="fs-14 fw-500">As the owner, this user has all permissions by default.</div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="bg-gray p-10 radius-8 mb-10">By default, staff members will only be able to see the events they are assigned to. If you would like to give them certain admin rights, you can configure their permissions below.</div>
          <Row gutter={[10,10]}>
            <Col xs={24} sm={12}>
              <div className="fw-700">Events</div>
              { renderPermissionCheckbox("VIEW_ALL_EVENTS", "Allows the staff member to view all of the company's events") }
              { renderPermissionCheckbox("CREATE_EVENTS", "Allows the staff member to create events") }
              { renderPermissionCheckbox("VIEW_EVENT_FINANCIALS", "Allows the staff member to see the pricing of services and add-ons when viewing the event details") }
              { renderPermissionCheckbox("EDIT_EVENT_FINANCIALS", "Allows the staff member to edit the pricing of services and add-ons of an event") }
              <div className="fw-700 mt-10">Services</div>
              { renderPermissionCheckbox("MANAGE_PACKAGES", "Allows the staff member to create and edit packages on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_ADD_ONS", "Allows the staff member to create and edit add-ons on behalf of the company") }
              <div className="fw-700 mt-10">Users</div>
              { renderPermissionCheckbox("MANAGE_CLIENTS", "Allows the staff member to view the list of clients and make updates to their information") }
              { renderPermissionCheckbox("MANAGE_EMPLOYEES", "Allows the staff member to view a list of all the company's staff and make updates to their information, including permissions. The staff member will not be able to modify the owner's permissions or their own.") }
              {/* <div className="fw-700 mt-10">Calendar</div>
              { renderPermissionCheckbox("VIEW_TIME_OFF", "Allows the staff member to view all time off that is entered into the system") }
              { renderPermissionCheckbox("MANAGE_TIME_OFF", "Allows the staff member to add, edit, and delete time off for any staff member in the company") } */}
            </Col>
            <Col xs={24} sm={12}>
              { FeatureFlags.CONTRACTS && (
                <>
                  <div className="fw-700">Documents</div>
                  { renderPermissionCheckbox("MANAGE_CONTRACTS", "Allows the staff member to view and edit all contracts for the company") }
                </>
              )}
              { isNewInvoicesEnabled && (
                <>
                  { renderPermissionCheckbox("MANAGE_INVOICES", "Allows the staff member to view and edit all invoices for the company") }
                </>
              )}
              <div className="fw-700 mt-10">Booking</div>
              { renderPermissionCheckbox("VIEW_BOOKING_REQUESTS", "Allows the staff member to view any incoming booking requests that result from a client filling out the form on a pricing guide") }
              { renderPermissionCheckbox("MANAGE_PRICING_GUIDES", "Allows the staff member to create and edit pricing guides on behalf of the company") }
              <div className="fw-700 mt-10">Setup</div>
              { renderPermissionCheckbox("MANAGE_SPECIAL_EVENTS", "Allows the staff member to create and edit special event templates on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_CONTRACT_TEMPLATES", "Allows the staff member to create and edit contract templates on behalf of the company") }
              { isNewInvoicesEnabled && renderPermissionCheckbox("MANAGE_INVOICE_TEMPLATES", "Allows the staff member to create and edit invoice templates on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_QUESTIONNAIRES", "Allows the staff member to create and edit questionanires on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_WORKFLOWS", "Allows the staff member to create and edit workflows on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_REC_PLAYLISTS", "Allows the staff member to create and edit recommended playlists on behalf of the company") }
              { renderPermissionCheckbox("MANAGE_VENUES", "Allows the staff member to create and edit venues on behalf of the company") }
            </Col>
          </Row>
        </div>
      )
    }
  }


  const renderProfileHeader = () => {
    var image = djPlaceholderImage
    var borderClass = ""
    if (imageUrl) {
      image = imageUrl
      borderClass = "gray-border"
    } else if (user.photo) {
      image = user.photo
      borderClass = "gray-border"
    }
    var nameSuffix = ""
    if (user.user_id == auth.user.user_id) {
      nameSuffix = "(You)"
    } else if (user.role == Roles.OWNER) {
      nameSuffix = "(Owner)"
    }
    return (
      <div className="shadow-card ph-20 pv-30 mb-15">
        <div className="text-center">
          <div className={`profile-photo-container ${borderClass}`}>
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageChange}
              maxCount={1}
              disabled={isReadOnly}
            >
              <div className="profile-photo" style={{ backgroundImage: `url(${image})`}}>
                <div className="photo-overlay">
                  <MdPhotoCamera/>
                </div>
              </div>
            </Upload>
          </div>
          <div className="fs-18 fw-700 mt-5">{ user.first_name } { user.last_name } {nameSuffix}</div>
          <div className="fs-14">{ user.email }</div>
        </div>
      </div>
    )
  }

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" onClick={() => form.submit()}>Save</button>
      )
    }
  }

  const renderUpdateEmailError = () => {
    return (
      <Modal visible={isUpdateEmailErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Already Exists</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">A user with the email address <span className="fw-700">{requestBody.email}</span> already exists. Please enter a different email address and try again.</div>
          <button className="primary-button" type="button" onClick={() => setUpdateEmailErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderUpdateEmailConfirmation = () => {
    return (
      <Modal visible={isUpdateEmailConfirmationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailConfirmationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Update</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to update this staff member's email address to <span className="fw-700">{requestBody.email}</span>? They will need to re-verify their email before signing in again.</div>
          <button className="primary-button" type="button" onClick={() => confirmEmailUpdate()}>Continue</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setUpdateEmailConfirmationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        { renderProfileHeader() }
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 mb-20 line-1">Staff Details</div>
          <Form form={form} layout="vertical" name="employee" onFinish={onSubmit}>
            <Row gutter={[10,10]}>
              <Col xs={24} md={12}>
                {renderInputField("First Name", "first_name", true, isReadOnly)}
              </Col>
              <Col xs={24} md={12}>
                {renderInputField("Last Name", "last_name", true, isReadOnly)}
              </Col>
              <Col xs={24}>
                {renderInputField("Alias", "alias", false, isReadOnly, "DJ Name")}
              </Col>
              <Col xs={24}>
                {renderEmailField("Email", "email", true, isReadOnly)}
              </Col>
              <Col xs={24}>
                {renderPhoneField("Phone", "phone", false, isReadOnly, phoneCountryCode, setPhoneCountryCode)}
              </Col>
              <Col xs={24}>
                {renderSearchSelectField("Timezone", "timezone", "Select Timezone", getTimezoneOptions(), true, null, isReadOnly)}
              </Col>
              { isNew && (
                <Col xs={24}>
                  { renderCheckboxField("Send invitation immediately", "send_invitation") }
                </Col>
              )}
            </Row>
          </Form>
        </div>
        <div className="shadow-card pv-15 ph-20 mb-15">
          <div className="fs-20 fw-700 mb-20 line-1">Permissions</div>
          { renderPermissions() }
        </div>
        { renderActivity() }
        { !isReadOnly && (
          <div className="sticky-footer p-0">
            <div className="p-15 bg-white t-border">
              {renderSaveButton()}
            </div>
          </div>
        )}
        { renderConfirmInvitationModal() }
        { renderUpdateEmailError() }
        { renderUpdateEmailConfirmation() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default NewEmployeePage;
