import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Checkbox, Dropdown, Menu, Grid, Modal, Form, notification } from 'antd';
import { cloneDeep, isNull, isArray, isUndefined } from 'lodash';
import moment from 'moment';
import qs from "qs";
import useApi from '../../../hooks/useApi';
import AppContext from '../../../app/context';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import AdminContent from '../../../components/adminContent';
import LoadingSpinner from '../../../components/loading';
import ErrorCard from '../../../components/errorCard';
import StatusTag from '../../../components/statusTag';
import TimeOffModal from '../../../modals/timeOffModal';
import Permissions from '../../../constants/permissions';
import TimeOffStatus from '../../../constants/timeOffStatus';
import CalendarRepeatOptions from '../../../constants/calendarRepeatOptions';
import { hasPermission } from '../../../helpers/permissionHelper';
import { getCalendarFilters, setCalendarFilters } from '../../../helpers/calendarHelper';
import { FiChevronLeft, FiChevronRight, FiEye, FiMoreHorizontal, FiCheck } from "react-icons/fi";
import { MdOutlineClose, MdOutlineMoreTime, MdFilterList } from "react-icons/md";
import { BsClockHistory } from "react-icons/bs";
import { FaRegCalendarPlus } from "react-icons/fa";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { 
  getEvents, 
  getAdminUsers, 
  getTimeOffByAccount, 
  deleteTimeOff,
  updateTimeOffStatus
} from '../../../api';
import { formatEventDateLong, formatEventDateShort, formatTime } from '../../../helpers/dateHelper';

const CalendarPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [events, setEvents] = useState([]);
  const [timeOffRecords, setTimeOffRecords] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
  const [isTimeOffModalVisible, setTimeOffModalVisible] = useState(false);
  const [isConfirmRemoveTimeOffMobalVisible, setConfirmRemoveTimeOffMobalVisible] = useState(false);
  const [isCalendarSettingsModalVisible, setCalendarSettingsModalVisible] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState({});
  const [groupByStaff, setGroupByStaff] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [tempGroupByStaff, setTempGroupByStaff] = useState(false);
  const [tempSelectedStaff, setTempSelectedStaff] = useState([]);

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}
  const canManageTimeOff = hasPermission(user, Permissions.MANAGE_TIME_OFF)
  const canViewAllEvents = hasPermission(user, Permissions.VIEW_ALL_EVENTS)

  useDocumentTitle("Calendar")
  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const location = useLocation()
  const timeOffModalRef = useRef();

  const [calendarSettingsForm] = Form.useForm();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

      var month = currentMonth
      var year = currentYear
      if (queryStrings.date) {
        month = moment(queryStrings.date, "YYYY-MM-DD").format("MM")
        year = moment(queryStrings.date, "YYYY-MM-DD").format("YYYY")
        setSelectedDay(queryStrings.date)
        setCurrentMonth(month)
        setCurrentYear(year)
      }

      const eventResults = await sendRequest(getEvents());
      setEvents(eventResults)

      const employeeResults = await sendRequest(getAdminUsers())
      setEmployees(employeeResults)

      const calendarFilters = getCalendarFilters(auth.user.user_id)
      const selectedStaffResults = !isUndefined(calendarFilters.selected_staff) && isArray(calendarFilters.selected_staff) ? calendarFilters.selected_staff : []
      setSelectedStaff(selectedStaffResults)
      setGroupByStaff(!isUndefined(calendarFilters.group_by_staff) ? calendarFilters.group_by_staff : false)

      await refreshTimeOff(month, year, selectedStaffResults)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const refreshTimeOff = async (month, year, staff) => {
    try {
      const filters = {
        filter: "all",
        month: month,
        year: year,
        staff: isUndefined(staff) ? [] : staff
      }
      const timeOffResults = await sendRequest(getTimeOffByAccount(filters));
      setTimeOffRecords(timeOffResults.results)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const refreshTimeOffFromModal = async () => {
    try {
      await refreshTimeOff(currentMonth, currentYear)
    } catch {}
  }

  const onSelectStaff = (id) => {
    var cloneSelected = cloneDeep(tempSelectedStaff)
    if (cloneSelected.includes(id)) {
      cloneSelected = cloneSelected.filter(x => x != id)
    } else {
      cloneSelected.push(id)
    }
    setTempSelectedStaff(cloneSelected)
  }

  const onConfirmTimeOffDelete = (timeOff) => {
    setSelectedTimeOff(timeOff)
    setConfirmRemoveTimeOffMobalVisible(true)
  }

  const confirmTimeOffDelete = async () => {
    try {
      await sendRequest(deleteTimeOff(selectedTimeOff.time_off_id))
      await refreshTimeOff(currentMonth, currentYear)
      setConfirmRemoveTimeOffMobalVisible(false)
    } catch(error) {
      setConfirmRemoveTimeOffMobalVisible(false)
    }
  }

  const goToMonth = (direction) => {
    const selectedMonthFirstDay = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD")
    let newDate = direction == "forward" ? selectedMonthFirstDay.add(1, "month") : selectedMonthFirstDay.subtract(1, "month");
    let newMonth = newDate.format("MM");
    let newYear = newDate.format("YYYY");
    setCurrentMonth(newMonth)
    setCurrentYear(newYear)
    refreshTimeOff(newMonth, newYear)
  }

  const selectDay = (date) => {
    var url = "/admin/calendar"
    if (selectedDay == date) {
      // setSelectedDay(null)
    } else {
      setSelectedDay(date)
      url = `/admin/calendar?date=${date}`
      navigate(url, { replace: true })
    }
  }

  const getFilteredEvents = () => {
    var filteredEvents = cloneDeep(events)
    const includeUnassigned = selectedStaff.includes(1)
    if (canViewAllEvents) {
      filteredEvents = filteredEvents.filter(x => {
        if (isArray(x.employees) && x.employees.length > 0) {
          const foundEmployee = x.employees.filter(e => selectedStaff.includes(e.user_id)).length > 0
          return foundEmployee
        }
        return includeUnassigned
      })
    }
    return filteredEvents
  }

  const getEventsForDate = (date) => {
    var filteredEvents = getFilteredEvents(true)
    filteredEvents = filteredEvents.filter((x) => x.event_date == date)
    return filteredEvents
  }

  const isTimeOffIncluded = (date, timeOff) => {
    const currentDate = moment(date, "YYYY-MM-DD")

    // If start and end dates are different, we don't allow repeating
    if (timeOff.start_date != timeOff.end_date) {
      // Check if current date is between the two dates (including start and end dates)
      const startDate = moment(timeOff.start_date, "YYYY-MM-DD")
      const endDate = moment(timeOff.end_date, "YYYY-MM-DD")
      return currentDate.isBetween(startDate, endDate, undefined, '[]')
    }
    
    // If there is no recurrence type, check if the current date is the same as the start date
    if (timeOff.recurrence_type == CalendarRepeatOptions.NONE || isNull(timeOff.recurrence_type)) {
      return date == timeOff.start_date
    }

    const startDate = moment(timeOff.start_date, "YYYY-MM-DD")
    const recurrenceEndDate = !isNull(timeOff.recurrence_end_date) ? moment(timeOff.recurrence_end_date, "YYYY-MM-DD") : null;
    const isBetween = !isNull(recurrenceEndDate) ? currentDate.isBetween(startDate, recurrenceEndDate, undefined, '[]') : currentDate.isSameOrAfter(startDate)

    // Check for recurrences
    if (timeOff.recurrence_type == CalendarRepeatOptions.DAILY) {
      // Daily
      return isBetween
    } else if (timeOff.recurrence_type == CalendarRepeatOptions.WEEKLY) {
      // Weekly
      const isSameWeekDay = startDate.isoWeekday() == currentDate.isoWeekday()
      return isBetween && isSameWeekDay
    } else if (timeOff.recurrence_type == CalendarRepeatOptions.MONTHLY) {
      // Monthly
      const isSameMonthDay = startDate.date() == currentDate.date()
      return isBetween && isSameMonthDay
    } else if (timeOff.recurrence_type == CalendarRepeatOptions.YEARLY) {
      // Monthly
      const isSameMonthDay = startDate.date() == currentDate.date()
      const isSameYearDay = startDate.month() == currentDate.month()
      return isBetween && isSameMonthDay && isSameYearDay
    }

    return false
  }

  const getTimeOffForDate = (date) => {
    var filteredTimeOff = cloneDeep(timeOffRecords)
    filteredTimeOff = filteredTimeOff.filter((x) => isTimeOffIncluded(date, x))
    return filteredTimeOff
  }

  const goToToday = () => {
    setSelectedDay(moment().format("YYYY-MM-DD"))
    const todayMonth = moment().format("MM")
    const todayYear = moment().format("YYYY")
    if (todayMonth != currentMonth || todayYear != currentYear) {
      refreshTimeOff(todayMonth, todayYear)
    }
    setCurrentMonth(todayMonth)
    setCurrentYear(todayYear)
  }

  const handleAddTimeOff = () => {
    if (timeOffModalRef.current) {
      timeOffModalRef.current.handleAddTimeOff();
    }
  }

  const handleEditTimeOff = (timeOff) => {
    if (timeOffModalRef.current) {
      timeOffModalRef.current.handleEditTimeOff(timeOff);
    }
  }

  const handleCancelCalendarSettings = () => {
    setCalendarSettingsModalVisible(false)
  }

  const onDisplayCalendarSettings = () => {
    setTempGroupByStaff(groupByStaff)
    setTempSelectedStaff(selectedStaff ?? [])
    setCalendarSettingsModalVisible(true)
  }

  const onSubmitCalendarSettings = () => {
    const finalSelectedStaff = tempSelectedStaff.length == 0 ? [auth.user.user_id] : tempSelectedStaff
    const filters = {
      group_by_staff: tempGroupByStaff,
      selected_staff: finalSelectedStaff
    }
    setGroupByStaff(tempGroupByStaff)
    setSelectedStaff(finalSelectedStaff ?? [])
    setCalendarFilters(auth.user.user_id, filters)
    setCalendarSettingsModalVisible(false)
    refreshTimeOff(currentMonth, currentYear, finalSelectedStaff)
  }

  const onUpdateTimeOffStatus = async (timeOff, status) => {
    try {
      await sendRequest(updateTimeOffStatus(timeOff.time_off_id, { status: status }))
      await refreshTimeOff(currentMonth, currentYear)
      notification.success({
        message: 'Success!',
        description: 'You have updated this time off request.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Please try again.',
        duration: 3
      });
    }
  }

  const getCalendarStartDay = () => {
    return accountSettings.calendar_start_day ?? "Sunday"
  }

  const createMenu = () => {
    return (
      <Menu>
        { hasPermission(user, Permissions.CREATE_EVENTS) && ( 
          <Menu.Item>
            <div style={{ padding: 2 }} onClick={() => navigate(`/admin/events/new`)}>
            <FaRegCalendarPlus style={{ marginRight: 8}}/> New Event
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div style={{ padding: 2 }} onClick={() => handleAddTimeOff()}>
          <MdOutlineMoreTime style={{ marginRight: 8}}/> Time Off
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const itemMenu = (item) => {
    return (
      <Menu>
        { (canManageTimeOff || item.user_id == auth.user.user_id) && ( 
          <>
            <Menu.Item>
              <div style={{ padding: 2 }} onClick={() => handleEditTimeOff(item)}>
              <EditOutlined style={{ marginRight: 8}}/> Edit
              </div>
            </Menu.Item>
            <Menu.Item>
              <div style={{ padding: 2 }} onClick={() => onConfirmTimeOffDelete(item)}>
                <DeleteOutlined style={{ marginRight: 8}}/> Delete
              </div>
            </Menu.Item>
          </>
        )}
      </Menu>
    )
  };

  const eventMenu = (event) => {
    return (
      <Menu>
        <Menu.Item>
          <div style={{ padding: 2 }} onClick={() => navigate(`/admin/events/${event.event_id}`)}>
            <FiEye style={{ marginRight: 8}}/> View Event
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Calendar</div>
        </Col>
        <Col flex={0}>
          <Dropdown overlay={createMenu()} placement="bottomRight" trigger="click">
            <button className="page-title-button" onClick={() => {}}>+ Add</button>
          </Dropdown>
        </Col>
      </Row>
    )
  }

  const renderBlankCalendarCell = (key) => {
    return <div key={key} className="flex-1 r-border bg-gray"></div>
  }

  const renderCalendarItem = (event, index) => {
    return (
      <Dropdown key={index} overlay={eventMenu(event)} trigger={'contextMenu'}>
        <div className="c-white" style={{ marginRight: 1, marginLeft: 1, padding: 5, marginBottom: 2, borderRadius: 4, backgroundColor: "#536DFE", minWidth: 0, display: 'block', maxWidth: '100%'}}>
          <div className="fs-12 fw-600" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{event.event_name}</div>
          <div className="fs-10" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{formatTime(event.start_time, accountSettings)} - {formatTime(event.end_time, accountSettings)}</div>
        </div>
      </Dropdown>
    )
  }

  const renderTimeOffItem = (timeOff, index, currentDate) => {
    var subtext = ""
    var alignmentClass = ""
    if (timeOff.all_day) {
      subtext = "All day"
    } else if (timeOff.start_date == timeOff.end_date) {
      subtext = formatTime(timeOff.start_time, accountSettings) + " - " + formatTime(timeOff.end_time, accountSettings) 
    } else if (timeOff.start_date == currentDate) {
      subtext = formatTime(timeOff.start_time, accountSettings)
    } else if (timeOff.end_date == currentDate) {
      subtext = formatTime(timeOff.end_time, accountSettings)
      alignmentClass = "text-right"
    } else {
      subtext = "--"
    }
    var icon = <MdOutlineClose size={10} style={{ marginRight: 2 }}/>
    if (timeOff.status == TimeOffStatus.APPROVED) {
      icon = <FiCheck size={10} style={{ marginRight: 2 }}/>
    } else if (timeOff.status == TimeOffStatus.PENDING) {
      icon = <BsClockHistory size={10} style={{ marginRight: 2 }}/>
    }
    return (
      <Dropdown key={index} overlay={itemMenu(timeOff)} trigger={'contextMenu'}>
        <div className="c-white" style={{ marginRight: 1, marginLeft: 1, padding: 5, marginBottom: 2, borderRadius: 4, backgroundColor: "#555555", minWidth: 0, display: 'block', maxWidth: '100%'}}>
          <div className="fs-12 fw-600" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{icon} {timeOff.first_name} {timeOff.last_name}</div>
          <div className={`fs-10 ${alignmentClass}`} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{subtext}</div>
        </div>
      </Dropdown>
    )
  }

  const renderCalendarCell = (day, key) => {
    const currentDay = day > 9 ? day : `0${day}`
    const currentCellDate = `${currentYear}-${currentMonth}-${currentDay}`
    const isToday = currentCellDate == moment().format("YYYY-MM-DD")
    const eventsForDay = getEventsForDate(currentCellDate)
    const timeOffForDay = getTimeOffForDate(currentCellDate)

    var selectedClass = selectedDay == currentCellDate ? "bg-light-blue" : ""
    const dayStyle = screens.sm ? {} : { display: "flex", justifyContent: "center" }

    return (
      <div key={key} className={`calendar--cell ${selectedClass}`} onClick={() => selectDay(currentCellDate)}>
        <div style={dayStyle}>
          <div className={`calendar--cell-date ${isToday ? "today" : ""}`}>
            <div>{day}</div>
          </div>
        </div>
        { screens.sm && eventsForDay.map((x,i) => renderCalendarItem(x, i))}
        { screens.sm && timeOffForDay.map((x,i) => renderTimeOffItem(x, i, currentCellDate))}
        { !screens.sm && (
          <div className="text-center mt-10">
            { eventsForDay.length > 0 && <div className="calendar--calendar-dot"></div>}
            { timeOffForDay.length > 0 && <div className="calendar--calendar-dot time-off"></div>}
          </div>
        )}
      </div>
    )
  }

  const renderCalendarContent = () => {
    const selectedMonth = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD")
    const firstDayIndex = selectedMonth.format("E");
    var adjustedFirstDayIndex = 0
    if (getCalendarStartDay() == "Monday") {
      adjustedFirstDayIndex = firstDayIndex == 0 ? 6 : firstDayIndex - 1;
    } else {
      adjustedFirstDayIndex = firstDayIndex == 7 ? 0 : firstDayIndex;
    }
    const daysInMonth = selectedMonth.daysInMonth()
    var numRows = Math.ceil((Number(daysInMonth) + Number(adjustedFirstDayIndex)) / 7);;

    var calendarContent = []
    var currentDayCounter = 1;
    var keyCounter = 1;
    let isFirstRow = true;

    for (let i = 1; i <= numRows; i++) {
      let rowContent = [];
      for (let x = 0; x < 7; x++) {
        // If first row...
        if (isFirstRow) {
          if (x < adjustedFirstDayIndex) {
            // Render blank cell
            rowContent.push(renderBlankCalendarCell(keyCounter));
          } else {
            // Otherwise, render calendar day
            rowContent.push(renderCalendarCell(currentDayCounter, keyCounter));
            currentDayCounter++;
          }
          // Move past first row if it's the last day of the row
          isFirstRow = x == 6 ? false : true;
        } else {
          // If counter is last than or equal to the number of days in the month
          if (currentDayCounter <= daysInMonth) {
            // Render calendar day
            rowContent.push(renderCalendarCell(currentDayCounter, keyCounter));
            currentDayCounter++;
          } else {
            // Otherwise, render blank cell
            rowContent.push(renderBlankCalendarCell(keyCounter));
          }
        }
        keyCounter++;
      }

      calendarContent.push(
        <div className="display-flex b-border" key={i}>
          {rowContent}
        </div>
      );
    }

    return calendarContent
  }

  const renderCalendar = () => {
    const headerText = `${moment(currentMonth, "MM").format("MMMM")} ${currentYear}`
    return (
      <div className="shadow-card admin-calendar">
        <div className="display-flex flex-middle pv-15 ph-20">
          <div className="flex-1">
            <span className="fs-24 fw-700">{moment(currentMonth, "MM").format("MMMM")}</span> <span className="fs-24">{currentYear}</span>
          </div>
          { canViewAllEvents && (
            <div className="calendar--filter mr-5" onClick={() => onDisplayCalendarSettings()}><MdFilterList size={20}/></div>
          )}
          <div className="flex-0">
            <div className="admin-calendar--arrow mr-5" onClick={() => goToMonth("back")}><FiChevronLeft/></div>
          </div>
          <div className="flex-0">
            <div className="mr-5 bg-light-blue ph-10 pv-5 fw-600" style={{ borderRadius: 4 }} onClick={() => goToToday()}>Today</div>
          </div>
          <div className="flex-0">
            <div className="admin-calendar--arrow" onClick={() => goToMonth("forward")}><FiChevronRight/></div>
          </div>
        </div>
        <div className="admin-calendar--day-header">
          { getCalendarStartDay() == "Sunday" ? (
            <>
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </>
          ) : (
            <>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
              <div>Sun</div>
            </>
          )}
        </div>
        { renderCalendarContent() }
      </div>
    )
  }

  const renderEventDetails = (event, index) => {
    var venueName = ""
    if (isArray(event.venues) && event.venues.length > 0) {
      const venue = event.venues[0]
      if (venue.city) {
        const cityState = venue.state ? `${venue.city}, ${venue.state}` : venue.city
        venueName = `${venue.venue_name} (${cityState})`
      } else {
        venueName = venue.venue_name
      }
    }

    return (
      <div className="border p-10" style={{ borderLeft: "4px solid #536DFE", paddingLeft: 10, marginTop: 10}} key={index}>
        <Row gutter={[15,15]} wrap={false}>
          <Col flex={1}>
            <div className="display-flex flex-middle fw-700">{event.event_name}</div>
          </Col>
          <Col flex={0}>
            <Dropdown overlay={eventMenu(event)} placement="bottomRight">
              <FiMoreHorizontal size={20} />
            </Dropdown>
          </Col>
        </Row>
        <div className="">{venueName}</div>
        <div className="">{formatTime(event.start_time, accountSettings)} - {formatTime(event.end_time, accountSettings)}</div>
        <div className="bg-gray p-10 mt-10">
          <Row gutter={[15,15]}>
            <Col xs={12}>
              <div className="fw-600">Event Type</div>
              <div className="">{event.account_event_type_name}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-600">Arrival Time</div>
              <div className="">{formatTime(event.arrival_time, accountSettings)}</div>
            </Col>
          </Row>
          <div className="b-border mv-10"></div>
          <div className="fw-600">Clients</div>
          { isArray(event.clients) && event.clients.map((x,i) => <div key={i}>{x.first_name} {x.last_name}</div>) }
          { (!isArray(event.clients) || event.clients.length == 0) && ( 
            <div>--</div> 
          )}
          <div className="b-border mv-10"></div>
          <div className="fw-600">Assigned Staff</div>
          { isArray(event.employees) && event.employees.map((x,i) => <div key={i}>{x.first_name} {x.last_name} <span className="c-text-gray">({x.role_name})</span></div>) }
          { (!isArray(event.employees) || event.employees.length == 0) && ( 
            <div>--</div> 
          )}
        </div>
        <button className="small-primary-button mt-10" style={{ width: '100%'}} onClick={() => navigate(`/admin/events/${event.event_id}`)}>View Event</button>
      </div>
    )
  }

  const renderTimeOffRecurrenceText = (timeOff) => {
    var repeatText = ""
    if (timeOff.recurrence_type != CalendarRepeatOptions.NONE) {
      repeatText = `Repeats ${timeOff.recurrence_type}`
      if (!isNull(timeOff.recurrence_end_date)) {
        repeatText += ` until ${formatEventDateShort(timeOff.recurrence_end_date, accountSettings)}`
      }
      return (
        <div className="fs-12 c-text-gray f-italic">{repeatText}</div>
      )
    }
    return null
  }

  const renderTimeOffRange = (timeOff) => {
    if (timeOff.start_date == timeOff.end_date) {
      if (timeOff.all_day) {
        return (
          <>
            <div className="fw-600">Start / End</div>
            <div>All day</div>
            { renderTimeOffRecurrenceText(timeOff) }
          </>
        )
      } else {
        return (
          <>
            <div className="fw-600">Start / End</div>
            <div>{formatTime(timeOff.start_time, accountSettings) + " - " + formatTime(timeOff.end_time, accountSettings)}</div>
            { renderTimeOffRecurrenceText(timeOff) }
          </>
        )
      }
    } else {
      if (timeOff.all_day) {
        return (
          <Row gutter={[15,15]}>
            <Col xs={12}>
              <div className="fw-600">Start</div>
              <div className="">{formatEventDateShort(timeOff.start_date, accountSettings)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-600">End</div>
              <div className="">{formatEventDateShort(timeOff.end_date, accountSettings)}</div>
            </Col>
          </Row>
        )
      } else {
        return (
          <Row gutter={[15,15]}>
            <Col xs={12}>
              <div className="fw-600">Start</div>
              <div className="">{formatEventDateShort(timeOff.start_date, accountSettings)}</div>
              <div className="">at {formatTime(timeOff.start_time, accountSettings)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-600">End</div>
              <div className="">{formatEventDateShort(timeOff.end_date, accountSettings)}</div>
              <div className="">at {formatTime(timeOff.end_time, accountSettings)}</div>
            </Col>
          </Row>
        )
      }
    }
  }

  const renderTimeOffDetails = (timeOff, index) => {
    return (
      <div className="border p-10" style={{ borderLeft: "4px solid #555555", marginTop: 10}} key={index}>
        <Row gutter={[10,10]}>
          <Col flex={1}>
            <div className="display-flex flex-middle fw-700 mt-10">Time Off <StatusTag status={timeOff.status} size="small" className="ml-10"/></div>
          </Col>
          { (canManageTimeOff || timeOff.user_id == auth.user.user_id) && (
            <Col flex={0}>
              <Dropdown overlay={itemMenu(timeOff)} placement="bottomRight">
                <FiMoreHorizontal size={20} />
              </Dropdown>
            </Col>
          )}
        </Row>
        <div className="display-flex flex-middle">{timeOff.first_name} {timeOff.last_name}</div>
        <div className="bg-gray p-10 mt-10">
          { renderTimeOffRange(timeOff) }
          <div className="b-border mv-10"></div>
          <div className="fw-600">Reason</div>
          <div className="">{timeOff.description}</div>
        </div>
        { timeOff.status == TimeOffStatus.PENDING && canManageTimeOff && (
          <Row gutter={[10,10]}>
            <Col xs={12}>
              <button className="small-secondary-button mt-10" style={{ width: '100%'}} onClick={() => onUpdateTimeOffStatus(timeOff, TimeOffStatus.REJECTED)}>Reject</button>
            </Col>
            <Col xs={12}>
              <button className="small-primary-button mt-10" style={{ width: '100%'}} onClick={() => onUpdateTimeOffStatus(timeOff, TimeOffStatus.APPROVED)}>Approve</button>
            </Col>
          </Row>
        )}
      </div>
    )
  }

  const renderCalendarDetails = () => {
    const eventsForDate = getEventsForDate(selectedDay)
    const timeOffForDate = getTimeOffForDate(selectedDay)
    if (isNull(selectedDay)) {
      return (
        <div className="p-15">
          <div className="fw-700 fs-16 text-center mt-15">No date selected</div>
          <div className="pb-5 text-center">Select a day to view details.</div>
        </div>
      )
    }
    return (
      <div className="p-15">
        <div className="calendar--menu--close-button" onClick={() => setSelectedDay(null)}><MdOutlineClose size={20}/></div>
        <div className="fw-700 b-border pb-5 fs-18 mb-10">{ formatEventDateLong(selectedDay, accountSettings) }</div>
        { eventsForDate.length == 0 && timeOffForDate.length == 0 && (
          <>
            <div className="text-center p-15 mt-20 c-text-gray">You don't have any events on this day.</div>
            { hasPermission(user, Permissions.CREATE_EVENTS) && ( 
              <div className="text-center">
                <button className="small-primary-button" onClick={() => navigate(`/admin/events/new`)}>New Event</button>
              </div>
            )}
          </>
        )}
        {(eventsForDate.length == 0 && timeOffForDate.length > 0) && canManageTimeOff && (
          <div className="bg-gray p-10 fs-12 mb-5">You have no events on this day, however {timeOffForDate.length} staff {timeOffForDate.length == 1 ? "member has" : "members have"} requested time off.</div>
        )}
        {(eventsForDate.length > 0 && timeOffForDate.length == 0) && (
          <div className="bg-gray p-10 fs-12 mb-5">You have {eventsForDate.length} {eventsForDate.length == 1 ? "event" : "events"} on this day.</div>
        )}
        {(eventsForDate.length > 0 && timeOffForDate.length > 0) && canManageTimeOff && (
          <div className="bg-gray p-10 fs-12 mb-5">You have {eventsForDate.length} {eventsForDate.length == 1 ? "event" : "events"} on this day and {timeOffForDate.length} staff {timeOffForDate.length == 1 ? "member who has" : "members who have"} requested time off.</div>
        )}
        { eventsForDate.map((x,i) => renderEventDetails(x,i))}
        { timeOffForDate.map((x,i) => renderTimeOffDetails(x,i))}
      </div>
    )
  }

  const renderCalendarSettingsModel = () => {
    return (
      <Modal visible={isCalendarSettingsModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Calendar Filters</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelCalendarSettings}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={calendarSettingsForm} layout="vertical" name="event-type" onFinish={onSubmitCalendarSettings}>
          <Row gutter={[15,15]} className="mt-15">
            <Col xs={24}>
              <div className="fw-700 mb-5">Show Events Assigned To</div>
              { employees.map((user, index) => (
                <div className="fs-14 mb-5" key={index}>
                  <Checkbox checked={tempSelectedStaff.includes(user.user_id)} value={true} onChange={(e) => onSelectStaff(user.user_id)}>{user.first_name} {user.last_name}</Checkbox>
                </div>
              ))}
               <div className="fs-14 mb-5">
                <Checkbox checked={tempSelectedStaff.includes(1)} value={true} onChange={(e) => onSelectStaff(1)}>Unassigned</Checkbox>
              </div>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Display Options</div>
              <div className="fs-14 mb-5">
                <Checkbox checked={tempGroupByStaff} value={true} onChange={(e) => setTempGroupByStaff(e.target.value)}>Group Items by Staff</Checkbox>
              </div>
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Apply</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelCalendarSettings}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveTimeOffModal = () => {
    return (
      <Modal visible={isConfirmRemoveTimeOffMobalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveTimeOffMobalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Time Off</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this time off request from <span className="fw-700">{selectedTimeOff.first_name} {selectedTimeOff.last_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmTimeOffDelete()}>Remove Time Off</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveTimeOffMobalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>;
    }

    if (isError) {
      return <ErrorCard/>
    }

    return (
      <>
        <div className="calendar">
          <div className={`calendar--content ${!screens.lg ? "collapsed" : ""}`}>
            { renderCalendar() }
            <div className="flex-1 flex-row mt-5 pr-10" style={{ justifyContent: 'flex-end'}}>
              <div className="mr-10"><span className="bg-blue" style={{ width: 10, height: 10, display: 'inline-block', borderRadius: 5, marginRight: 5 }}></span>Events</div>
              <div><span style={{ backgroundColor: "#555555", width: 10, height: 10, display: 'inline-block', borderRadius: 5, marginRight: 5 }}></span>Time Off</div>
            </div>
          </div>
          <div className={`calendar--menu ${!isNull(selectedDay) ? "show" : "collapsed"}`}>
            { renderCalendarDetails() }
          </div>
        </div>
        <TimeOffModal
          isTimeOffModalVisible={isTimeOffModalVisible}
          setTimeOffModalVisible={setTimeOffModalVisible}
          employees={employees}
          setSelectedTimeOff={setSelectedTimeOff}
          selectedTimeOff={selectedTimeOff}
          refreshTimeOff={refreshTimeOffFromModal}
          selectedDay={selectedDay}
          ref={timeOffModalRef}
        />
        { renderConfirmRemoveTimeOffModal() }
        { renderCalendarSettingsModel() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default CalendarPage;
